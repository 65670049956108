.loader {
  width: 100%;
  margin: 2rem auto;
  border-radius: 10px;
  border: 4px solid transparent;
  position: relative;
  padding: 1px;
}

.loader:before {
  content: '';
  border: 2px solid #359b3c;
  border-radius: 10px;
  position: absolute;
  top: -2px;
  right: -4px;
  bottom: -4px;
  left: -4px;
}

.loader .loaderBar {
  position: absolute;
  border-radius: 10px;
  top: 0;
  right: 100%;
  bottom: 0;
  left: 0;
  background: #359b3c;
  width: 0;
  animation: borealisBar 2s linear infinite;
  padding: 2px;
}

@keyframes borealisBar {
  0% {
    left: 0%;
    right: 100%;
    width: 0%;
  }
  10% {
    left: 0%;
    right: 75%;
    width: 25%;
  }
  90% {
    right: 0%;
    left: 75%;
    width: 25%;
  }
  100% {
    left: 100%;
    right: 0%;
    width: 0%;
  }
}
